@font-face {
  font-family: Catalogue;
  font-weight: 300;
  font-style: normal;
  src: url("../../../Catalogue-Book-u4h4dr.4a583073.woff2") format("woff2"), url("../../../Catalogue-Book-c5aqpq.1734b3fa.woff") format("woff");
}

@font-face {
  font-family: Catalogue;
  font-weight: 300;
  font-style: italic;
  src: url("../../../Catalogue-BookItalic-couqwq.e7f63315.woff2") format("woff2"), url("../../../Catalogue-BookItalic-i0hvdi.d73e5833.woff") format("woff");
}

@font-face {
  font-family: Catalogue;
  font-weight: 400;
  font-style: normal;
  src: url("../../../Catalogue-Regular-wgcnse.651bc781.woff2") format("woff2"), url("../../../Catalogue-Regular-jp4puw.220ce06b.woff") format("woff");
}

@font-face {
  font-family: Catalogue;
  font-weight: 400;
  font-style: italic;
  src: url("../../../Catalogue-Italic-2hrjtf.63c4f278.woff2") format("woff2"), url("../../../Catalogue-Italic-29gbhp.369cb97c.woff") format("woff");
}

@font-face {
  font-family: Catalogue;
  font-weight: 500;
  font-style: normal;
  src: url("../../../Catalogue-Medium-svc8pe.663a4ad3.woff2") format("woff2"), url("../../../Catalogue-Medium-1riq96.9b8cd60d.woff") format("woff");
}

@font-face {
  font-family: Catalogue;
  font-weight: 600;
  font-style: normal;
  src: url("../../../Catalogue-Demi-7hfiun.3c7f69af.woff2") format("woff2"), url("../../../Catalogue-Demi-kacmpp.d41a8127.woff") format("woff");
}
/*# sourceMappingURL=index.1852f5ea.css.map */
